import {
    digiMr,
    country,
  } from "../../js/path";
  
  export default {
    data() {
      return {
        pageNo: null,
        filter: null,
        filterOn: [],
        tableData: [],
        sortBy: "id",
        sortDesc: true,
        currentPage: 1,
        key: 0,
        fields: [{
            key: "id",
            label: "ID",
            sortable: true,
          },
        //   {
        //     key: "name",
        //     label: "Name",
        //     sortable: true,
        //   },
          {
            key: "username",
            label: "Username",
          },
          {
            key: "email",
            label: "Email",
          },
          {
            key: "flyy_referal_code",
            label: "Referral Link",
          },
        //   {
        //     key: "field_mr",
        //     label: "Field Mr",
        //   },
        //   {
        //     key: "is_active",
        //     label: "Status"
        //   },
          {
            key: "edit",
          },
          {
            key: 'delete'
          }
        ],
        form: {
            name: '',
            username: '',
            password: '',
            email: '',
            country: '',
            field_mr: 0,
            is_active: 0,
            reporting_url:"",
            reporting_url_expire_date:""
        },
        allCountry: [],
        // questionTypes: [],
      };
    },
    methods: {
    //   addOption() {
    //     let length = this.form.options.length;
    //     this.form.options.push({
    //       key: length,
    //       value: "",
    //     });
    //   },
    //   removeOption(key) {
    //     if (key == 0) return;
    //     this.form.options.splice(key, 1);
    //   },
        search(event) {
            if (this.filter.length > 1) {
            if (event.keyCode == 13) {
                this.fetchData('search');
            }
            } else if (this.filter.length == 0) this.fetchData('search');
        },
        searchFor() {
            if (this.filter.length > 0) this.fetchData("search");
            else if (this.filter.length == 0) this.fetchData("search");
        },
        filterPage() {
            if (this.pageNo.length > 0) {
            this.params = `&page=${this.pageNo}`;
            this.fetchData(this.activeTab);
            this.currentPage = this.pageNo;
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
       
        async fetchData(pagination = null) {
            this.$store.commit("loader/updateStatus", true);
            try {
            let url = digiMr.fetchDigimr;
            if (pagination == "search") {
                url = digiMr.fetchDigimr + "?search=" + this.filter;
            } else if (pagination) {
                url = url + "?page=" + pagination;
            }
            const data = await this.getRequest(url);
            if (data.status) {
                const responseData = data.response;
                this.tableData = responseData;
            }
            } catch (err) {
            this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: err.data ? err.data.message : "Please try again!",
            });
            }
            this.$store.commit("loader/updateStatus", false);
        },
      async fetchDigiMr(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
          let url = digiMr.fetchSingleDigimr + "/" + id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.digimr_id = responseData.id;

            if (responseData.name != null && responseData.name != "") {
                this.form.name = responseData.name;
            }

            if (responseData.username != null && responseData.username != "") {
              this.form.username = responseData.username;
            }

            if (responseData.reporting_url != null && responseData.reporting_url != "") {
              this.form.reporting_url = responseData.reporting_url;
            }

            if (responseData.reporting_url_expire_date != null && responseData.reporting_url_expire_date != "") {
              this.form.reporting_url_expire_date = responseData.reporting_url_expire_date;
            }

            if (responseData.email != null && responseData.email != "") {
                this.form.email = responseData.email;
            }

            if (responseData.country != null && responseData.country != "") {
                this.form.country = this.fetchSingleCountry(responseData.country)
            }
  
            if (responseData.email != "" && responseData.email != null) {
              this.form.email = responseData.email;
            }
            if (responseData.is_field_mr) {
              this.form.field_mr = true;
            }
            
            if (responseData.is_active) {
              this.form.is_active = true;
            }
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
      
        async deleteDigiMr(id) {
            this.$store.commit("loader/updateStatus", true);
            try {
            let index = this.tableData.data.findIndex((e) => e.id === id);
            const url =  digiMr.deleteDigimr + "/" + id;
            const data = await this.postRequest(url, {
                _method: "DELETE",
            });
            if (data.status) {
                this.tableData.data.splice(index, 1);
                this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: data.message,
                });
            }
            } catch (err) {
            this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: err.data ? err.data.message : "Please try again!",
            });
            }
            this.$store.commit("loader/updateStatus", false);
        },
        async fetchAllCountry(query) {
            const url = country.countryUrl;
            const data = await this.getRequest(url + "?search=" + query);
            if (data.status) {
                this.allCountry = data.response.data;
            }
        },
        async fetchSingleCountry(name) {
            const url = country.countryUrl;
            const data = await this.getRequest(url + "/" + name + '?country_name=1');
            if (data.status) {
                this.form.country = data.response;
            }
        },
        async submitData() {
            try {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                if(this.$route.name == "add-digiMr" || (this.$route.name == "edit-digiMr" && this.$v.form.email.$invalid && this.$v.form.username.$invalid && this.$v.form.name.$invalid && this.$v.form.country.$invalid && this.$v.form.field_mr.$invalid)){
                    this.$store.commit("toast/updateStatus", {
                    status: true,
                    icon: "error",
                    title: "Please Fill The Required Fields"
                    });
                    return false;
                }
            }
            this.$store.commit("loader/updateStatus", true);
            let url = digiMr.addDigimr;
            if (this.$route.name == 'edit-digiMr') {
                url = digiMr.updateDigimr + '/' + this.digimr_id;
            }
            let dataAppend = new FormData();
            for (var key in this.form) {
                if (key != "field_mr" && key!= "country" && key != "is_active") {
                    dataAppend.append(key, this.form[key]);
                }
            }
            if (this.form.field_mr) {
                dataAppend.append("field_mr", 1);
                } else {
                dataAppend.append("field_mr", 0);
            }
            if (this.form.is_active) {
                dataAppend.append("is_active", 1);
                } else {
                dataAppend.append("is_active", 0);
            }
            if (this.form.country != "") {
                dataAppend.append(
                    "country",this.form.country.name
                );
            } else {
                dataAppend.append("country", "");
            }
            const data = await this.postRequest(url, dataAppend);
            if (data.status) {
                this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "success",
                title: data.message,
                });
                this.$router.push("/digiMr-management");
            }
            } catch (err) {
            console.log(err);
            this.$store.commit("toast/updateStatus", {
                status: true,
                icon: "error",
                title: err.data ? err.data.message : "Please try again!",
            });
            }
            this.$store.commit("loader/updateStatus", false);
        },
        async updateStatus(id) {
            this.$store.commit("loader/updateStatus", true);
            try {
               let index = this.tableData.data.findIndex((e) => e.id === id);
               const url = digiMr.statusUpdate;
               const data = await this.postRequest(url, {
                  is_active: !this.tableData.data[index].is_active,
                  id: id,
               });
               if (data.status) {
                  this.tableData.data[index].is_active = !this.tableData.data[index].is_active;
                  this.$store.commit("toast/updateStatus", {
                     status: true,
                     icon: "success",
                     title: data.message,
                  });
               }
               this.key += 1;
            } catch (err) {
               this.$store.commit("toast/updateStatus", {
                  status: true,
                  icon: "error",
                  title: err.data ? err.data.message : "Please try again!",
               });
            }
            this.$store.commit("loader/updateStatus", false);
        },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (value) {
            this.params = `&page=${value}`;
            this.fetchData(value);
            this.pageNo = this.currentPage;
          }
        },
      },
    },
    mounted() {
      if (this.$route.name == "add-digiMr" || this.$route.name == "edit-digiMr") {
        if (this.$route.name == "edit-digiMr") {
          this.fetchDigiMr(this.$route.params.id);
        }
      } else {
        this.fetchData();
      }
    },
  };